/*
// .product-image
*/
@import '../variables';
@import '../mixins/hacks';


.product-image {}
.product-image__body {
    position: relative;
    //padding-bottom: #{100% * (1 / $product-image-ratio)};
    width: 100%;
    //min-height: 160px;
    height: 450px;
    display: flex;
    align-items: center;
}
.product-image__img {
    display: block;
    //position: absolute;
    position: relative;
    width: 100%;
    height: 100%;
border-radius: 5px;
border:  1px solid #EDEDED;;
    object-fit: contain;
    

    &,
    .owl-carousel .owl-item & {
        @include only-ie {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }

    &:hover{
        filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.05));
        transition: filter 0.5s;
    }
}
.product-image--location--gallery {
    .owl-carousel .owl-item & .product-image__img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}
