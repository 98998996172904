/*
// .slick-prevent-click
*/
@import '../variables';


.slick-prevent-click {
    & > .slick-slider {
        &.slick-initialized {
            & > .slick-list {
                & > .slick-track {
                    margin-left: 0;
                    .slick-slide {
                        // margin: 10px 0 !important;
                    }
                }
            }
        }
    }
    margin: 10px 0;
}
.slick-prevent-click--active {
    .slick-list:before {
        position: absolute;
        content: '';
        display: block;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1000;
      
    }
}

.slick-prevent-click {
  margin: 0;
}
