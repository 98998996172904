/*
// .site
*/
@import '../variables';



.site {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}
.site__header {
    flex-shrink: 0;
}

.header_osition_in_site{
    position: sticky;
    top: 0;
    background: #ffffff ;
    z-index: 10;
}
.site__body {
    flex-grow: 1;
}
.site__footer {
    flex-shrink: 0;
}