/*
// .account-nav
*/
@import "../variables";
@import "../functions";
@import "../mixins/card";
@import "../mixins/direction";

.account-nav {
    ul {
        li {
            border-top: 1px solid #cccccc;
            border-right: 1px solid #cccccc;
        }
        li:last-child {
            border-bottom: 1px solid #cccccc;
        }
        list-style: none;
        margin: 0;
        padding: 0;
    }
}
.account-nav__item a {
    display: block;
    color: $body-font-alt-color;
    font-size: 15px;
    display: grid;
    grid-template-columns: 30px 1fr;
    
    &:hover {
        svg {
            fill: #ffffff;
        }
        svg path {
            stroke: #ffffff;
        }
        color: #ffffff;
        background-color: #ffd633;
    }
}

@media (min-width: breakpoint(lg-start)) {
    .account-nav {
        ul {
            padding-bottom: 18px;
        }
    }
    .account-nav__title {
        font-size: 20px;
        padding: 24px 26px 0;
        margin-bottom: 1rem;
    }
    .account-nav__item a {
        display: flex;
        align-items: center;
        padding: 24px 15px;

        span {
            margin-left: 20px;
        }
        @include direction {
            #{$margin-inline-start}: -2px;
            #{$border-inline-start}: $card-border-width solid $card-border-color;
        }
    }
    .account-nav__item--active a {
        color: $body-font-color;
        font-weight: $font-weight-medium;

        @include direction {
            #{$border-inline-start}: $card-border-width solid $accent-color;
        }
    }
}

@media (max-width: breakpoint(md-end)) {
    .account-nav__title {
        display: none;
    }
    .account-nav {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        ul {
            display: flex;
            flex-wrap: nowrap;
            white-space: nowrap;
            width: 100%;
            border-bottom: $card-border-width solid $card-border-color;
        }
    }
    .account-nav__item a {
        padding: 8px 16px;
        margin-bottom: -2px;
        border-bottom: $card-border-width solid $card-border-color;
    }
    .account-nav__item--active a {
        color: $body-font-color;
        font-weight: $font-weight-medium;
        background-color: $accent-color;
    }
}
