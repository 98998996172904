/*
// .site-header
*/
@import "../variables";
@import "../functions";
@import "../mixins/direction";

.site-header {
    position: sticky !important;
    top: 0 !important;
    z-index: 100;
    background: $header-bg;
    color: $header-font-color;
}
.site-header__middle {
    display: flex;
    align-items: center;
    height: 104px;
}
.site-header__logo {
    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    color: $header-logo-color;

    @include direction {
        //#{$margin-inline-end}: 30px;
    }

    a {
        color: inherit;
    }

    svg {
        fill: currentColor;
    }
}
.site-header__search {
    flex-grow: 1;
    max-width: 600px;
    margin-left: auto;
}
.site-header__phone {
    line-height: 1;
    flex-shrink: 0;

    @include direction {
        text-align: $inline-end;
        //#{$margin-inline-start}: 80px;
        margin-left: auto;
    }
}
.site-header__phone-title {
    font-size: 14px;
    color: $header-font-muted-color;
    margin-bottom: 6px;
}
.site-header__phone-number {
    font-size: 20px;
    font-weight: $font-weight-bold;
}
.site-header__nav-panel {
    height: $nav-panel-height;
    margin-bottom: 20px;
}

@media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
    .site-header__logo {
        width: 120px;
        justify-content: flex-start;

        @include direction {
            //#{$margin-inline-end}: 24px;
        }
    }

    .site-header__search {
       
        max-width: 450px;
        
    }
}





